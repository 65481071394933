import { default as abuseReports864ST9nL8eMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93cfLsBRXnVyMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/auditLog/[username]/[id].vue?macro=true";
import { default as indexCMhYM6LoMRMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/index.vue?macro=true";
import { default as moderation78USTEv0l6Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/moderation.vue?macro=true";
import { default as pendingBansIWsPPwHtB8Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/pendingBans.vue?macro=true";
import { default as profiles2J7fyl5p2xMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/profiles.vue?macro=true";
import { default as indexxVclI43iB8Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/timesheets/index.vue?macro=true";
import { default as overview6n3Qj8KsRJMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingx7rlikMUKLMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingmlIQVtBWD7Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/translations/missing.vue?macro=true";
import { default as usersH391AKMe50Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/users.vue?macro=true";
import { default as apiW1P0xCRlH1Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/api.vue?macro=true";
import { default as _91slug_93pFxpjr8lYfMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/blog/[slug].vue?macro=true";
import { default as indexht3p2xi4SdMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93jduWNQvgXyMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93TzmdPG6Cv7Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminB2E9YMuoj1Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/census/admin.vue?macro=true";
import { default as indexQgv8uXzhJgMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/census/index.vue?macro=true";
import { default as contactTfeiZAmv9ZMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/contact.vue?macro=true";
import { default as designWxmysEXHdhMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/design.vue?macro=true";
import { default as englishpuRjegNuTKMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/english.vue?macro=true";
import { default as faqZo8Vw6b0hZMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/faq.vue?macro=true";
import { default as inclusiveexSrrzVaWIMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/inclusive.vue?macro=true";
import { default as indexeBOzw8zt0vMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/index.vue?macro=true";
import { default as license6yTGbom7KfMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/license.vue?macro=true";
import { default as academicP9JY4i0KGBMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/links/academic.vue?macro=true";
import { default as indexooXgEs15mbMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/links/index.vue?macro=true";
import { default as mediaCagCOVnanfMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/links/media.vue?macro=true";
import { default as translinguistics6X4Vyk2lOdMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/links/translinguistics.vue?macro=true";
import { default as zined0LCMocWIqMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/links/zine.vue?macro=true";
import { default as namesUPchV7pSHjMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/names.vue?macro=true";
import { default as index58mpayOqRGMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/nouns/index.vue?macro=true";
import { default as templatesSZdkt8wIqzMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/nouns/templates.vue?macro=true";
import { default as people64Wwg6QJSCMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/people.vue?macro=true";
import { default as privacyZdN8WdEqseMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/privacy.vue?macro=true";
import { default as _91username_93YlplHcOwg5Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93PkqvTaKAguMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/card-[username].vue?macro=true";
import { default as editori2Td7kpEdLMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/editor.vue?macro=true";
import { default as anyxbNJbbmggOMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/any.vue?macro=true";
import { default as avoidingQf67BUXFXZMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/avoiding.vue?macro=true";
import { default as indexn5LxOIulyQMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/index.vue?macro=true";
import { default as mirrorxd7JALkIOmMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/mirror.vue?macro=true";
import { default as pronounjZ8IZYeH1IMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/pronoun.vue?macro=true";
import { default as sources0EQ5W54LfTMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/sources.vue?macro=true";
import { default as teambY0dPDmer1Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/team.vue?macro=true";
import { default as terminologyMP6qPxTtCZMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/terminology.vue?macro=true";
import { default as termsw9WLZUJNXQMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/terms.vue?macro=true";
import { default as userujElz8RCT1Meta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/user.vue?macro=true";
import { default as workshopsOk67GnZbVBMeta } from "/home/admin/www/zaimki.pl/release/20240916205450/pages/workshops.vue?macro=true";
import { default as neutratywy1pA2aUI8UMMeta } from "~/data/nouns/neutratywy.vue?macro=true";
import { default as dukatywyefwx6B0yWaMeta } from "~/data/nouns/dukatywy.vue?macro=true";
import { default as osobatywyJt6U0sHeFZMeta } from "~/data/nouns/osobatywy.vue?macro=true";
import { default as iksatywy4G8TfyCT5SMeta } from "~/data/nouns/iksatywy.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports864ST9nL8eMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/abuseReports.vue").then(m => m.default || m)
  },
  {
    name: "admin-auditLog-username-id",
    path: "/admin/auditLog/:username()/:id()",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/auditLog/[username]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/moderation.vue").then(m => m.default || m)
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansIWsPPwHtB8Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/pendingBans.vue").then(m => m.default || m)
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/profiles.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/timesheets/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/translations/awaiting.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/translations/missing.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    meta: apiW1P0xCRlH1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93pFxpjr8lYfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexht3p2xi4SdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93jduWNQvgXyMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/calendar/[[year]].vue").then(m => m.default || m)
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93TzmdPG6Cv7Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/calendar/[year]-[month]-[day].vue").then(m => m.default || m)
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminB2E9YMuoj1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/census/admin.vue").then(m => m.default || m)
  },
  {
    name: "census",
    path: "/census",
    meta: indexQgv8uXzhJgMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/census/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactTfeiZAmv9ZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: englishpuRjegNuTKMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/english.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqZo8Vw6b0hZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveexSrrzVaWIMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/inclusive.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicP9JY4i0KGBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/links/academic.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    meta: indexooXgEs15mbMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaCagCOVnanfMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/links/media.vue").then(m => m.default || m)
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguistics6X4Vyk2lOdMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/links/translinguistics.vue").then(m => m.default || m)
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zined0LCMocWIqMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/links/zine.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/names",
    meta: namesUPchV7pSHjMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: index58mpayOqRGMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/nouns/index.vue").then(m => m.default || m)
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesSZdkt8wIqzMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/nouns/templates.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: people64Wwg6QJSCMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyZdN8WdEqseMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93YlplHcOwg5Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93PkqvTaKAguMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/card-[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editori2Td7kpEdLMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/profile/editor.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyxbNJbbmggOMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/any.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingQf67BUXFXZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/avoiding.vue").then(m => m.default || m)
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexn5LxOIulyQMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/index.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorxd7JALkIOmMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/mirror.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounjZ8IZYeH1IMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/pronouns/pronoun.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources0EQ5W54LfTMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/sources.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: teambY0dPDmer1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyMP6qPxTtCZMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/terminology.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsw9WLZUJNXQMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: userujElz8RCT1Meta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsOk67GnZbVBMeta || {},
    component: () => import("/home/admin/www/zaimki.pl/release/20240916205450/pages/workshops.vue").then(m => m.default || m)
  },
  {
    name: neutratywy1pA2aUI8UMMeta?.name,
    path: "/neutratywy",
    component: () => import("~/data/nouns/neutratywy.vue").then(m => m.default || m)
  },
  {
    name: dukatywyefwx6B0yWaMeta?.name,
    path: "/dukatywy",
    component: () => import("~/data/nouns/dukatywy.vue").then(m => m.default || m)
  },
  {
    name: osobatywyJt6U0sHeFZMeta?.name,
    path: "/osobatywy",
    component: () => import("~/data/nouns/osobatywy.vue").then(m => m.default || m)
  },
  {
    name: iksatywy4G8TfyCT5SMeta?.name,
    path: "/iksatywy",
    component: () => import("~/data/nouns/iksatywy.vue").then(m => m.default || m)
  }
]