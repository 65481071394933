import validate from "/home/admin/www/zaimki.pl/release/20240916205450/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/validate.js";
import atom_45global from "/home/admin/www/zaimki.pl/release/20240916205450/middleware/atom.global.ts";
import redirect_45global from "/home/admin/www/zaimki.pl/release/20240916205450/middleware/redirect.global.ts";
import manifest_45route_45rule from "/home/admin/www/zaimki.pl/release/20240916205450/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  atom_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "layout-from-query": () => import("/home/admin/www/zaimki.pl/release/20240916205450/middleware/layoutFromQuery.ts")
}